<template>
  <view class="content">
    <svg
      class="background"
      xmlns="http://www.w3.org/2000/svg"
      width="750"
      height="675.688"
      viewBox="0 0 750 675.688"
    >
      <g id="undraw_well_done_i2wr" transform="translate(0 0)">
        <path
          id="路径_1"
          data-name="路径 1"
          d="M455.213,315.572c-12.9-38.55-52.721-59.975-78.92-51.211s-45.115,49.841-32.219,88.39c11.937,35.683,48.108,56.282,72.991,52.606a2.822,2.822,0,0,0-.348,1.012l-.761,6.346a2.858,2.858,0,0,0,3.8,3.031l12.818-4.6a2.858,2.858,0,0,0,1.011-4.757l-4.62-4.416a2.827,2.827,0,0,0-.541-.389C450.619,389.628,467.176,351.332,455.213,315.572Z"
          transform="translate(-269.389 -144.173)"
          fill="#f2f2f2"
        />
        <path
          id="路径_2"
          data-name="路径 2"
          d="M398.673,284.155a47.026,47.026,0,0,1,21.28,61.17c-1.109,2.525,2.97,3.853,4.071,1.344a51.42,51.42,0,0,0-22.973-66.083C398.632,279.3,396.236,282.863,398.673,284.155Z"
          transform="translate(-259.535 -141.117)"
          fill="#fff"
        />
        <path
          id="路径_3"
          data-name="路径 3"
          d="M901.213,267.572c-12.9-38.55-52.721-59.975-78.92-51.211s-45.115,49.841-32.219,88.39c11.937,35.683,48.108,56.282,72.991,52.606a2.821,2.821,0,0,0-.348,1.012l-.761,6.346a2.858,2.858,0,0,0,3.8,3.031l12.818-4.6a2.858,2.858,0,0,0,1.01-4.757l-4.62-4.416a2.831,2.831,0,0,0-.541-.389C896.619,341.628,913.176,303.332,901.213,267.572Z"
          transform="translate(-193.269 -152.365)"
          fill="#f2f2f2"
        />
        <path
          id="路径_4"
          data-name="路径 4"
          d="M844.673,236.155a47.026,47.026,0,0,1,21.28,61.17c-1.109,2.525,2.97,3.853,4.071,1.344a51.42,51.42,0,0,0-22.973-66.083C844.632,231.3,842.236,234.863,844.673,236.155Z"
          transform="translate(-183.415 -149.31)"
          fill="#fff"
        />
        <path
          id="路径_5"
          data-name="路径 5"
          d="M642.213,214.572c-12.9-38.55-52.721-59.975-78.92-51.211s-45.115,49.841-32.219,88.39c11.937,35.683,48.108,56.282,72.991,52.606a2.821,2.821,0,0,0-.348,1.012l-.761,6.346a2.858,2.858,0,0,0,3.8,3.031l12.818-4.6a2.858,2.858,0,0,0,1.011-4.757l-4.62-4.416a2.827,2.827,0,0,0-.541-.389C637.619,288.628,654.176,250.332,642.213,214.572Z"
          transform="translate(-237.473 -161.411)"
          fill="#f2f2f2"
        />
        <path
          id="路径_6"
          data-name="路径 6"
          d="M585.673,183.155a47.026,47.026,0,0,1,21.28,61.17c-1.109,2.525,2.97,3.853,4.071,1.344a51.42,51.42,0,0,0-22.972-66.083C585.632,178.3,583.236,181.863,585.673,183.155Z"
          transform="translate(-227.619 -158.355)"
          fill="#fff"
        />
        <circle
          id="椭圆_1"
          data-name="椭圆 1"
          cx="3.072"
          cy="3.072"
          r="3.072"
          transform="translate(336.987 232.511)"
          fill="#ccc"
        />
        <circle
          id="椭圆_2"
          data-name="椭圆 2"
          cx="6.069"
          cy="6.069"
          r="6.069"
          transform="translate(327.442 349.764)"
          fill="#ccc"
        />
        <circle
          id="椭圆_3"
          data-name="椭圆 3"
          cx="3.507"
          cy="3.507"
          r="3.507"
          transform="translate(228.395 331.518)"
          fill="#ccc"
        />
        <circle
          id="椭圆_4"
          data-name="椭圆 4"
          cx="3.798"
          cy="3.798"
          r="3.798"
          transform="translate(281.384 273.511)"
          fill="#ccc"
        />
        <circle
          id="椭圆_5"
          data-name="椭圆 5"
          cx="3.072"
          cy="3.072"
          r="3.072"
          transform="translate(469.002 497.328)"
          fill="#ccc"
        />
        <circle
          id="椭圆_6"
          data-name="椭圆 6"
          cx="6.069"
          cy="6.069"
          r="6.069"
          transform="translate(365.386 428.159)"
          fill="#ccc"
        />
        <circle
          id="椭圆_7"
          data-name="椭圆 7"
          cx="3.507"
          cy="3.507"
          r="3.507"
          transform="translate(437.063 353.386)"
          fill="#ccc"
        />
        <circle
          id="椭圆_8"
          data-name="椭圆 8"
          cx="3.798"
          cy="3.798"
          r="3.798"
          transform="translate(459.835 428.183)"
          fill="#ccc"
        />
        <ellipse
          id="椭圆_9"
          data-name="椭圆 9"
          cx="7.914"
          cy="25.209"
          rx="7.914"
          ry="25.209"
          transform="translate(629.773 597.823) rotate(-39.938)"
          fill="#2f2e41"
        />
        <circle
          id="椭圆_10"
          data-name="椭圆 10"
          cx="50.418"
          cy="50.418"
          r="50.418"
          transform="translate(547.979 552.892)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_3"
          data-name="矩形 3"
          width="15.317"
          height="27.443"
          transform="translate(604.42 642.878)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_4"
          data-name="矩形 4"
          width="15.317"
          height="27.443"
          transform="translate(573.786 642.878)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_11"
          data-name="椭圆 11"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(594.209 665.853)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_12"
          data-name="椭圆 12"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(563.575 665.215)"
          fill="#2f2e41"
        />
        <path
          id="路径_7"
          data-name="路径 7"
          d="M777.409,626.279c4.5-18.13,24.374-28.8,44.386-23.83s32.585,23.695,28.083,41.825-19.438,18.189-39.449,13.22S772.907,644.409,777.409,626.279Z"
          transform="translate(-194.843 -86.345)"
          fill="#e6e6e6"
        />
        <ellipse
          id="椭圆_13"
          data-name="椭圆 13"
          cx="7.914"
          cy="25.209"
          rx="7.914"
          ry="25.209"
          transform="translate(515.444 576.262) rotate(-64.626)"
          fill="#2f2e41"
        />
        <circle
          id="椭圆_14"
          data-name="椭圆 14"
          cx="16.809"
          cy="16.809"
          r="16.809"
          transform="translate(574.05 575.862)"
          fill="#fff"
        />
        <circle
          id="椭圆_15"
          data-name="椭圆 15"
          cx="5.603"
          cy="5.603"
          r="5.603"
          transform="translate(578.348 581.043)"
          fill="#3f3d56"
        />
        <path
          id="路径_8"
          data-name="路径 8"
          d="M796.635,698.443a11.206,11.206,0,0,1-22.05,4.014h0l0-.022c-1.1-6.09,3.606-8.245,9.7-9.347S795.533,692.353,796.635,698.443Z"
          transform="translate(-195.231 -70.751)"
          fill="#fff"
        />
        <ellipse
          id="椭圆_16"
          data-name="椭圆 16"
          cx="25.209"
          cy="7.914"
          rx="25.209"
          ry="7.914"
          transform="matrix(0.357, -0.934, 0.934, 0.357, 160.104, 585.962)"
          fill="#2f2e41"
        />
        <circle
          id="椭圆_17"
          data-name="椭圆 17"
          cx="50.418"
          cy="50.418"
          r="50.418"
          transform="translate(79.479 552.892)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_5"
          data-name="矩形 5"
          width="15.317"
          height="27.443"
          transform="translate(106.922 642.878)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_6"
          data-name="矩形 6"
          width="15.317"
          height="27.443"
          transform="translate(137.555 642.878)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_18"
          data-name="椭圆 18"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(106.922 665.853)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_19"
          data-name="椭圆 19"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(137.555 665.215)"
          fill="#2f2e41"
        />
        <circle
          id="椭圆_20"
          data-name="椭圆 20"
          cx="17.231"
          cy="17.231"
          r="17.231"
          transform="translate(113.942 573.314)"
          fill="#fff"
        />
        <circle
          id="椭圆_21"
          data-name="椭圆 21"
          cx="5.744"
          cy="5.744"
          r="5.744"
          transform="translate(125.429 584.802)"
          fill="#3f3d56"
        />
        <path
          id="路径_9"
          data-name="路径 9"
          d="M348.962,642.856c-4.071-18.232,8.942-36.654,29.066-41.148s39.738,6.644,43.809,24.875-9.266,24.956-29.39,29.449S353.033,661.088,348.962,642.856Z"
          transform="translate(-267.972 -86.434)"
          fill="#e6e6e6"
        />
        <ellipse
          id="椭圆_22"
          data-name="椭圆 22"
          cx="7.914"
          cy="25.209"
          rx="7.914"
          ry="25.209"
          transform="translate(46.943 576.263) rotate(-64.626)"
          fill="#2f2e41"
        />
        <path
          id="路径_10"
          data-name="路径 10"
          d="M369.476,693.192c0,4.935,12.706,14.679,26.8,14.679S423.6,693.979,423.6,689.044,410.379,690,396.28,690,369.476,688.258,369.476,693.192Z"
          transform="translate(-264.344 -71.694)"
          fill="#fff"
        />
        <circle
          id="椭圆_23"
          data-name="椭圆 23"
          cx="50.418"
          cy="50.418"
          r="50.418"
          transform="translate(320.309 504.894)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_7"
          data-name="矩形 7"
          width="15.317"
          height="27.443"
          transform="translate(347.752 594.88)"
          fill="#2f2e41"
        />
        <rect
          id="矩形_8"
          data-name="矩形 8"
          width="15.317"
          height="27.443"
          transform="translate(378.385 594.88)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_24"
          data-name="椭圆 24"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(347.752 617.856)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_25"
          data-name="椭圆 25"
          cx="12.764"
          cy="4.787"
          rx="12.764"
          ry="4.787"
          transform="translate(378.385 617.217)"
          fill="#2f2e41"
        />
        <path
          id="路径_11"
          data-name="路径 11"
          d="M554.682,601.856c-4.071-18.232,8.942-36.654,29.066-41.148s39.738,6.644,43.809,24.875-9.266,24.956-29.39,29.449S558.753,620.088,554.682,601.856Z"
          transform="translate(-232.862 -93.431)"
          fill="#222a36"
        />
        <ellipse
          id="椭圆_26"
          data-name="椭圆 26"
          cx="27.97"
          cy="8.781"
          rx="27.97"
          ry="8.781"
          transform="translate(392.862 540.65) rotate(-45.022)"
          fill="#2f2e41"
        />
        <ellipse
          id="椭圆_27"
          data-name="椭圆 27"
          cx="8.781"
          cy="27.97"
          rx="8.781"
          ry="27.97"
          transform="translate(284.334 517.01) rotate(-44.978)"
          fill="#2f2e41"
        />
        <path
          id="路径_12"
          data-name="路径 12"
          d="M880.159,375.724c-1.674-51.7-37.081-92.5-79.084-91.142-93.482,2.77-95.765,174.2-5.511,186.512l-1.911,15.19,27.875-.9-2.889-15.034C854.945,462.321,881.677,422.593,880.159,375.724Z"
          transform="translate(-202.917 -140.394)"
          fill="#ff6584"
        />
        <path
          id="路径_13"
          data-name="路径 13"
          d="M752.985,395.615l-10.531.341a171.5,171.5,0,0,1-1.932-59.673l10.531-.341Q745.219,367.112,752.985,395.615Z"
          transform="translate(-201.314 -131.623)"
          fill="#fff"
        />
        <rect
          id="矩形_9"
          data-name="矩形 9"
          width="31.608"
          height="7.024"
          transform="matrix(0.999, -0.032, 0.032, 0.999, 588.389, 330.739)"
          fill="#3f3d56"
        />
        <path
          id="路径_14"
          data-name="路径 14"
          d="M451.672,550.16l-3.181-1.489,78.215-265.242,3.181,1.49Z"
          transform="translate(76.545 48.374)"
          fill="#3f3d56"
        />
        <path
          id="路径_15"
          data-name="路径 15"
          d="M935.227,417.289c19.1-48.067,2.925-99.615-36.129-115.135-86.817-34.775-157.339,121.492-79.5,168.809l-7.816,13.165,25.918,10.3,3.352-14.938C877.542,486.623,917.91,460.867,935.227,417.289Z"
          transform="translate(-193.25 -138.243)"
          fill="#222a36"
        />
        <path
          id="路径_16"
          data-name="路径 16"
          d="M806.782,388.062l-9.791-3.891a171.5,171.5,0,0,1,22.048-55.484l9.791,3.891Q811.039,358.828,806.782,388.062Z"
          transform="translate(-191.379 -132.861)"
          fill="#fff"
        />
        <rect
          id="矩形_10"
          data-name="矩形 10"
          width="7.024"
          height="31.608"
          transform="translate(619.826 337.585) rotate(-68.328)"
          fill="#3f3d56"
        />
        <path
          id="路径_17"
          data-name="路径 17"
          d="M451.125,541.893l-3.052-1.737,109.2-249.2,3.052,1.736Z"
          transform="translate(76.474 49.658)"
          fill="#3f3d56"
        />
        <path
          id="路径_18"
          data-name="路径 18"
          d="M428.1,374.134c-20.666-47.416-68.65-72.242-107.175-55.451-85.836,37.131-24.585,197.259,63.827,175.337l3.84,14.82L414.162,497.7l-8.243-12.9C436.688,463.918,446.84,417.122,428.1,374.134Z"
          transform="translate(-279.671 -135.464)"
          fill="#222a36"
        />
        <path
          id="路径_19"
          data-name="路径 19"
          d="M320.735,435.666l-9.659,4.21a171.5,171.5,0,0,1-23.854-54.732l9.659-4.21Q302.982,412.052,320.735,435.666Z"
          transform="translate(-278.383 -123.944)"
          fill="#fff"
        />
        <rect
          id="矩形_11"
          data-name="矩形 11"
          width="31.608"
          height="7.024"
          transform="matrix(0.917, -0.4, 0.4, 0.917, 101.135, 360.167)"
          fill="#3f3d56"
        />
        <path
          id="路径_20"
          data-name="路径 20"
          d="M459.522,708.837h0a1.756,1.756,0,0,1-2.127-1.281l-76.337-241.88,3.409-.845L460.8,706.71A1.756,1.756,0,0,1,459.522,708.837Z"
          transform="translate(-262.367 -109.625)"
          fill="#3f3d56"
        />
        <path
          id="路径_21"
          data-name="路径 21"
          d="M492.476,738.931h-183.8a1.171,1.171,0,1,1,0-2.341h183.8a1.171,1.171,0,0,1,0,2.341Z"
          transform="translate(-274.92 -63.243)"
          fill="#ccc"
        />
        <path
          id="路径_22"
          data-name="路径 22"
          d="M891.476,738.931h-183.8a1.171,1.171,0,1,1,0-2.341h183.8a1.171,1.171,0,1,1,0,2.341Z"
          transform="translate(-206.822 -63.243)"
          fill="#ccc"
        />
        <path
          id="路径_23"
          data-name="路径 23"
          d="M704.477,697.931h-183.8a1.171,1.171,0,0,1,0-2.341h183.8a1.171,1.171,0,0,1,0,2.341Z"
          transform="translate(-238.737 -70.241)"
          fill="#ccc"
        />
        <circle
          id="椭圆_28"
          data-name="椭圆 28"
          cx="17.231"
          cy="17.231"
          r="17.231"
          transform="translate(351.374 530.936)"
          fill="#fff"
        />
        <circle
          id="椭圆_29"
          data-name="椭圆 29"
          cx="5.744"
          cy="5.744"
          r="5.744"
          transform="translate(362.862 542.423)"
          fill="#3f3d56"
        />
        <path
          id="路径_24"
          data-name="路径 24"
          d="M609.461,657.735a11.206,11.206,0,1,1-22.05,4.014h0l0-.022c-1.1-6.09,3.606-8.245,9.7-9.348S608.359,651.645,609.461,657.735Z"
          transform="translate(-227.177 -77.699)"
          fill="#fff"
        />
      </g>
    </svg>

    <view class="background-container">
      <TopBar style="flex-shrink:0"></TopBar>

      <LeaveRoom class="create-room-button" @click="clickLeaveRoom"></LeaveRoom>
    </view>
  </view>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import LeaveRoom from "@/components/LeaveRoom.vue";
import AgoraRTC from "agora-rtc-sdk-ng";

// import Api from "@/api/index.js";
import { useRouter } from "vue-router";
export default {
  components: {
    TopBar,
    LeaveRoom
  },
  setup() {
    let rtc = {
      // 用来放置本地客户端
      client: null,
      // 用来放置本地音频轨道对象
      localAudioTrack: null
    };
    let options = {
      appId: "e6a6d2a2e0794d1ebf2fa8c09a8c22e5",
      channel: "demo_channel_name",
      token:
        "006e6a6d2a2e0794d1ebf2fa8c09a8c22e5IAAysQIrx7k/VgXigYrFpyBEx24AtSOSMy73g83KOyLMao4kO3kAAAAAEABwjq6POqIuYAEAAQA5oi5g"
    };
    async function startBasicCall() {
      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      rtc.client.on("user-published", async (user, mediaType) => {
        // 开始订阅远端用户
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe success");
        // 表示本次订阅的是音频
        if (mediaType === "audio") {
          // 订阅完成后，从 `user` 中获取远端音频轨道对象
          const remoteAudioTrack = user.audioTrack;
          // 播放音频因为不会有画面，不需要提供 DOM 元素的信息
          remoteAudioTrack.play();
        }
      });
      // 加入频道并获取 UID
      const uid = await rtc.client.join(
        options.appId,
        options.channel,
        options.token,
        null
      );
      console.log("uid", uid);
      ("AgoraRTCError CAN_NOT_GET_GATEWAY_SERVER: invalid vendor key, can not find appid");
      // 通过麦克风采集的音频创建本地音频轨道对象
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // 将这些音频轨道对象发不到频道中
      await rtc.client.publish([rtc.localAudioTrack]);
      console.log("publish success!");
    }
    startBasicCall();

    const leaveRoom = async () => {
      // 销毁本地音频轨道。
      rtc.localAudioTrack.close();

      // 离开频道。
      await rtc.client.leave();
    };

    const router = useRouter();

    const clickLeaveRoom = () => {
      leaveRoom();
      router.replace("/");
    };
    return {
      clickLeaveRoom
    };
  }
};
</script>

<style lang="stylus" scoped>
.content {
  background #d2dae9
  text-align center
  height 100vh
  width 100vw
  position relative
  display flex
  align-items center
  justify-content center
}

.background {
  height 50.65vh
  width 100vw
  position absolute
  bottom 0
  left 0
  right 0
  z-index 1
}

.background-container {
  width 98.4vw
  height 99.1vh
  background rgba(255, 255, 255, 0.7)
  box-shadow 0px 0.22vh 2.62vh rgba(0, 0, 0, 0.16)
  border-radius 0.67vh
  z-index 2
  display flex
  flex-direction column
  align-items center
  overflow scroll
}

.create-room-button {
  position fixed
  left 5.73vw
  right 5.73vw
  bottom 6.97vh
}
</style>
