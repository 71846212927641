<template>
  <view class="create-room-container" @click="$emit('click')">
    <view class="create-room-tips">💨 离开房间</view>
  </view>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.create-room-container {
  display flex
  align-items center
  justify-content center
  width 88.53vw
  height 6.82vh
  background rgba(255, 255, 255, 0.85)
  box-shadow 0px 0.22vh 2.62vh rgba(0, 0, 0, 0.16)
  border-radius 0.67vh
}

.create-room-tips {
  font-size 1.95vh
  font-family PingFang SC
  font-weight 600
  color #222a36
  opacity 1
}
</style>
